.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 52px;
  height: 100%;
  padding: 0 0 0 11px;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 300px;
  max-height: 220px;
  overflow-y: scroll;
  border-radius: 7px;
}

.react-tel-input .country-list .country {
  position: relative;
  padding: 12px 9px 13px 46px;
}

.react-tel-input .country-list .flag {
  display: inline-block;
  position: absolute;
  left: 13px;
  top: 8px;
}
